import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { addWorker } from '@/@core/queries/workers'
import { useRouter } from '@core/utils/utils'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import { ref } from '@vue/composition-api'
import useEventsUpload from "@/views/organization/confirmation-planning/events-upload/useEventsUpload";
// import realmConnection from '@/views/habit/realm'

export default function useWorkerAdd() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { router } = useRouter()
  const { bulkEventsUpload } = useEventsUpload();
  const userData = store.state?.userStore?.userData
  const isSubmitting = ref(false)
  // const { createItem } = realmConnection()

  function create(data) {
    isSubmitting.value = true
    const payload = {}
    if (data.name?.value) payload.name = data.name.value
    if (data.email?.value) payload.email = data.email.value
    if (data.supervisors?.value) payload.supervisors = { link: data.supervisors.value }
    if (data.locations?.value) payload.locations = { link: data.locations.value }
    if (data.roles?.value) payload.roles = data.roles.value
    if (data.privileges?.value) payload.privileges = data.privileges?.value
    payload.isComplaintManager = data.isComplaintManager?.value || false
    payload.client_id = (userData.role !== 'admin') ? { link: userData.client.$oid } : null

    const role = data.privileges?.value ? data.privileges.value : null
    const defaultPassword = '12345678'

    // createItem({collection: 'worker', payload: {name: data.name.value, privileges: data.privileges.value}})

    store
      .dispatch('workerStoreModule/createWorker', { query: addWorker, variables: { data: payload } })
      .then(async response => {
        const worker_id = response.data.data.insertOneWorker._id
        if (role !== 'employee') {
          useJwt.register({ email: payload.email, password: defaultPassword, name: payload.name }).then(async () => {
            try {
              const logUser = await useJwt.login({ email: payload.email, password: defaultPassword })
              await useJwt.updateDefaultData({
                id: logUser.id,
                username: payload.name,
                project: '',
                role,
                // eslint-disable-next-line no-underscore-dangle
                worker_id,
              })
              await useJwt.realm.allUsers[logUser.id].logOut()
              showSuccessMessage(i18n.t('message.worker_added'))

              // Bulk P.C. (events) Upload
              if (data.isPCUploadEnabled?.value) await bulkEventsUpload([worker_id], false)
            } catch (e) {
              showErrorMessage(e.message)
            } finally {
              router.push({ name: 'organization-worker-list' })
              isSubmitting.value = false
            }
          }).catch(error => {
            showErrorMessage(error.message)
            router.push({ name: 'organization-worker-list' })
            isSubmitting.value = false
          })
        } else {
          showSuccessMessage(i18n.t('message.worker_added'))

          // Bulk P.C. (events) Upload
          if (data.isPCUploadEnabled?.value) await bulkEventsUpload([worker_id], false)

          router.push({ name: 'organization-worker-list' })
          isSubmitting.value = false
        }
      })
      .catch(() => {
        showErrorMessage(i18n.t('message.worker_error'))
        isSubmitting.value = false
      })
  }

  return {
    create,
    isSubmitting,
  }
}
